export const registerControllers = (application, controllers) => {
  for (const path in controllers) {
    const controller = controllers[path].default;

    if (typeof controller === "function") {
      const controllerName = path
        .split("/")
        .slice(3)
        .join("--")
        .replace(/_controller\.[tj]s$/, "")
        .replace(/_/g, "-");
      application.register(controllerName, controller);
    }
  }
};
