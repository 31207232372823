import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import _ from "lodash";

export default class extends Controller {
  track() {
    const stage = (
      document.querySelector('meta[name="rails_stage"]') as HTMLMetaElement
    ).content;
    const element = this.element as HTMLElement;
    const data = this.convertKeysToSnakeCase(element.dataset);

    ahoy.track(element.role, data);
    window.dataLayer.push({
      event: "universal_event",
      universal_event_name: element.role,
      params: { ...data, stage: stage },
    });
  }

  convertKeysToSnakeCase(obj) {
    const excludedKeys = ["controller", "action"];

    return Object.keys(obj).reduce((acc, key: string) => {
      if (excludedKeys.includes(key)) {
        return acc;
      }

      const snakeCaseKey = _.snakeCase(key);
      acc[snakeCaseKey] = obj[key];
      return acc;
    }, {});
  }
}
